<template>
	<PaddingContainer
		class="cards-wrapper"
		:padding="padding"
		component="section"
	>
		<div v-if="blogPostsData.blogPosts.length" class="card-grid">
			<BlogPostCard
				v-for="blogPost in blogPostsData.blogPosts"
				:key="blogPost.id"
				:blog-post="blogPost"
			/>
		</div>
		<ErrorMessage
			v-else
			title="Nenhum post encontrado"
			:description="errorMessage"
		/>
		<LoadingIcon v-show="shouldShowLoading" class="loading" />

		<ButtonBlock
			v-show="!isInfiniteScrollEnabled && hasMoreBlogPostsToShow"
			class="show-more-blog-posts-button"
			text="VER MAIS POSTS"
			variant="square"
			size="medium"
			:is-outlined="false"
			@click="enableInfiniteScroll"
		/>

		<div
			v-show="isInfiniteScrollEnabled"
			ref="scrollSentinel"
			class="scroll-sentinel"
		></div>
	</PaddingContainer>
</template>

<script setup lang="ts">
import { useIntersectionObserver, whenever } from '@vueuse/core';

import type { BlogPost } from '@SHARED/core/entities/BlogPost';
import type { BlogPostsListingSection } from '@SHARED/core/entities/sections/BlogPostsListingSection';

import PaddingContainer from '@SHARED/components/molecules/PaddingContainer.vue';
import ButtonBlock from '@SHARED/components/blocks/ButtonBlock.vue';
import ErrorMessage from '@SHARED/components/molecules/ErrorMessage.vue';
import BlogPostCard from '@/components/molecules/BlogPostCard.vue';

import LoadingIcon from '~icons/mdi/loading';

defineOptions({ name: 'BlogPostsListingSection' });

const props = withDefaults(defineProps<BlogPostsListingSection['config']>(), {
	perPage: 6
});

const blogPostsRepository = inject(BLOG_POSTS_REPOSITORY)!;

const domain = useState<string>('domain');

const page = ref<number>(1);

const isScrollSentinelVisible = ref<boolean>(false);

const isInfiniteScrollEnabled = ref<boolean>(false);

const totalBlogPostsCount = ref<number | null>(null);

const totalFilteredBlogPosts = ref<number | null>(null);

const hasMoreBlogPostsToShow = computed<boolean>(() => {
	if (totalBlogPostsCount.value === null) return true;

	return (
		blogPostsData.value.blogPosts.length < (totalFilteredBlogPosts.value || 0)
	);
});

const shouldShowLoading = computed<boolean>(
	() => isFetchingBlogPosts.value && hasMoreBlogPostsToShow.value
);

const blogPostsOffset = computed<number>(
	() => props.perPage * (page.value - 1)
);

const scrollSentinel = ref<HTMLElement | null>(null);

const {
	data: blogPostsData,
	pending: isFetchingBlogPosts,
	execute: fetchBlogPostsAsyncData
} = useAsyncData<{
	blogPosts: BlogPost[];
	totalCount: number | null;
	filteredCount: number | null;
}>(`${domain.value}:blog-posts-section`, fetchBlogPosts, {
	default: () => ({
		blogPosts: [],
		totalCount: null,
		filteredCount: null
	})
});

const errorMessage = ref<string | null>(null);

async function fetchBlogPosts(): Promise<{
	blogPosts: BlogPost[];
	totalCount: number;
	filteredCount: number;
}> {
	if (!hasMoreBlogPostsToShow.value) {
		return {
			blogPosts: blogPostsData.value.blogPosts,
			totalCount: totalBlogPostsCount.value || 0,
			filteredCount: 0
		};
	}

	const [blogPostsResult, blogPostsRequestError] =
		await blogPostsRepository.getCompanyBlogPosts({
			domain: domain.value,
			maxQuantity: props.perPage,
			offset: blogPostsOffset.value
		});

	if (blogPostsRequestError || !blogPostsResult) {
		const notFoundError = {
			response: {
				status: 404
			}
		};

		const error =
			(blogPostsRequestError?.originalErrorObject as any) || notFoundError;

		// <!-- TODO: improve error handling -->
		// <!-- TODO: adicionar mensagens de erro para filtros -->
		// eslint-disable-next-line no-console
		console.error(blogPostsRequestError);

		errorMessage.value =
			error?.response.status === 404
				? 'No momento, não temos nenhuma postagem disponível.'
				: 'Ocorreu um erro ao carregar as postagens.';

		return {
			blogPosts: [],
			totalCount: 0,
			filteredCount: 0
		};
	}

	const blogPosts =
		page.value > 1
			? blogPostsData.value.blogPosts.concat(blogPostsResult.blogPosts)
			: blogPostsResult.blogPosts;

	return {
		blogPosts,
		filteredCount: blogPostsResult.filteredCount,
		totalCount: blogPostsResult.totalCount
	};
}

function enableInfiniteScroll() {
	isInfiniteScrollEnabled.value = true;
}

useIntersectionObserver(
	scrollSentinel,
	([{ isIntersecting }]) => {
		if (!isInfiniteScrollEnabled.value || !hasMoreBlogPostsToShow.value) return;

		isScrollSentinelVisible.value = isIntersecting;
	},
	{ threshold: 1 }
);

whenever(isScrollSentinelVisible, () => {
	if (!hasMoreBlogPostsToShow.value || isFetchingBlogPosts.value) return;

	page.value++;
});

whenever(page, async () => {
	if (page.value === 1) return;

	await fetchBlogPostsAsyncData();
});

watch(
	blogPostsData,
	() => {
		totalBlogPostsCount.value = blogPostsData.value.totalCount;
		totalFilteredBlogPosts.value = blogPostsData.value.filteredCount;
	},
	{ immediate: true, deep: true }
);
</script>

<style lang="scss" scoped>
@import '@/assets/style/mixins/breakpoint.scss';

.cards-wrapper {
	display: flex;
	flex-direction: column;
	width: 100%;
	gap: 3rem;

	.card-grid {
		display: grid;
		grid-template-columns: repeat(1, minmax(0, 1fr));
		gap: 1.5rem;

		@include screen-up(md) {
			grid-template-columns: repeat(2, minmax(0, 1fr));
		}

		@include screen-up(xl) {
			grid-template-columns: repeat(3, minmax(0, 1fr));
		}
	}
}

.show-more-blog-posts-button {
	align-self: center;
}

.loading {
	align-self: center;
	width: 3.5rem;
	height: 3.5rem;
}
</style>
